import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Menu, Image, Dropdown, Button } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { signOutFirebase } from "../../app/firestore/firebaseService";
import { getUserFromFirestore } from "../../app/firestore/firestoreService";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { addRoleToUser } from "../auth/authActions";

export default function SignedInMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.auth);

  useFirestoreDoc({
      query: () => getUserFromFirestore(currentUser.uid),
      data: (userFromFirestore) => dispatch(addRoleToUser(userFromFirestore.role)),
      deps:[currentUser.uid, dispatch]
  })

  async function handleSignOut() {
    try {
      localStorage.removeItem("accessToken")
      window.location.reload(false);
      //await signOutFirebase();
      history.push("/");
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <Menu.Item position='right'>
      <Image avatar spaced='right' src={require('../../assets/user.png')} />
      <Dropdown pointing='top left' text={currentUser.displayName}>
        <Dropdown.Menu>
          {/* <Dropdown.Item
            as={Link}
            to={"/account"}
            text='My Account'
            icon='settings'
          /> */}
          <Dropdown.Item onClick={handleSignOut} text='Sign Out' icon='power' />
        </Dropdown.Menu>
      </Dropdown>
      {currentUser.role === 'admin' && (
        <Button
        onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
        basic
        inverted
        content='Register'
        style={{ marginLeft: "0.5em" }}
      />
      
      )}
      
    </Menu.Item>
  );
}
