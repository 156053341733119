import firebase from "../config/firebase";
import { setUserProfileData } from "./firestoreService";
import { getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";

export function signInWithEmail(creds) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password);
}

export function signOutFirebase() {
  return firebase.auth().signOut();
}

export async function registerInFirebase(creds) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(creds.email, creds.password);
    await result.user.updateProfile({
      displayName: creds.displayName
    });
    return await setUserProfileData({user: result.user, creds});
  } catch (error) {
    throw error;
  }
}

export function updateUserPassword(creds){
    const user = firebase.auth().currentUser;
    return user.updatePassword(creds.newPassword1);
}

export function uploadFileToFirebaseStorage(file, filename, courseId, assignmentId){
  console.log(courseId, assignmentId)
  const storage = getStorage();
  const storageRef = ref(storage, `courses2022/${courseId.toLowerCase()}/assignments/assignment${assignmentId}/submitted/${filename}`);
  const uploadTask = uploadBytesResumable(storageRef, file)
  return uploadTask
  //const storageRef = firebase.storage().ref();
  //return storageRef.child(`courses2022/${courseId.toLowerCase()}/assignments/assignment${assignmentId}/submitted/${filename}`).put(file);
}

export function uploadCommonFileToFirebaseStorage(file, filename, docType){
  const storageRef = firebase.storage().ref();
  return storageRef.child(`courses2022/${docType.toLowerCase()}/${filename}`).put(file);
}
