import React from "react";
import { useSelector } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import CourseListItem from "./CourseListItem";

export default function CourseList({ courses }) {
  const { currentUser } = useSelector((state) => state.auth);

  console.log("Courses", courses)

  return (
    <>
      {courses.map((course) => {
        const isUserRegisteredForCourse = course.users.find(
          (user) => user.userId === currentUser?.uid
        );
        if (isUserRegisteredForCourse || currentUser?.role ==='grader' || currentUser?.role === 'admin' || currentUser?.role === 'teacher') {
          return <CourseListItem course={course} key={course.id} />;
        } else {
          return (
            <Segment placeholder key={1}>
              <Header
                textAlign='center'
                content='You are not registered for any courses. Please Contact Iskcon Dallas to know more about Bhakti Shashtri Courses'
              />
            </Segment>
          );
        }
      })}
    </>
  );
}
