import React, { useEffect, useState } from "react";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import AssignmentList from "./AssignmentList";
import {
  listenToAssignmentsFromFireStore,
  listenToCourseFromFirestore,
} from "../../app/firestore/firestoreService";
import useFirestoreCollection from "../../app/hooks/useFireStoreCollection";
import { listenToAssignments } from "./assignmentActions";
import CourseListPlaceholder from "../courses/CourseListPlaceholder";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { listenToCourse } from "../courses/courseActions";
import { fetchAssignments, fetchModules } from "../../app/api/apiservice";

export default function AssignmentDashboard({ match }) {
  const dispatch = useDispatch();
  const [assignmentsFromApi, setAssignments] = useState([]);
  const [questionBankUrl, setQuestionBankUrl] = useState();

  // useFirestoreCollection({
  //   query: () => listenToAssignmentsFromFireStore(match.params.id),
  //   data: (assignments) => dispatch(listenToAssignments(assignments)),
  //   deps: [dispatch],
  // });

  // useFirestoreDoc({
  //   query: () => listenToCourseFromFirestore(match.params.id),
  //   data: (course) => dispatch(listenToCourse(course)),
  //   deps: [dispatch],
  // });

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchAssignments();

      if (result == null) {
        console.log("api error");
      } else {
        setAssignments(...assignmentsFromApi, result.data.data);
      }

      const modules = await fetchModules();

      if (result == null) {
        console.log("api error");
      } else {
        console.log(
          modules.data.data.find((x) => x.id == match.params.id)?.questionBank
        );
        setQuestionBankUrl(
          modules.data.data.find((x) => x.id == match.params.id)?.questionBank
        );
      }
    };

    fetchData().catch(console.error);
  }, []);

  const { assignments } = useSelector((state) => state.assignment);
  const { course } = useSelector((state) => state.course);
  const { loading } = useSelector((state) => state.async);

  let assignmentsToPassChild = [];

  if (match.params.id === "c139284b-51af-402f-b612-bd4a4768447a") {
    assignmentsToPassChild = assignmentsFromApi?.filter(
      (ass) => ass.id === 5 || ass.id === 6 || ass.id === 7 || ass.id === 8
    );
   
  } else if (match.params.id === "0a983531-2e8a-4131-b707-7b4b6922807e") {
    assignmentsToPassChild = assignmentsFromApi?.filter(
      (ass) => ass.id === 9 || ass.id === 10 || ass.id === 11 || ass.id === 14 || ass.id === 15
    );
    
  }else if (match.params.id === "e6395e0b-0aa7-4fe0-b6b0-710bdb12c5c5") {
    assignmentsToPassChild = assignmentsFromApi?.filter(
      (ass) => ass.id === 16 || ass.id === 17 || ass.id === 18 || ass.id === 19 || ass.id === 20
    );
  }else if (match.params.id === "843b2744-1368-45ed-8995-84245b547991") {
    assignmentsToPassChild = assignmentsFromApi?.filter(
      (ass) => ass.id === 21 || ass.id === 22 || ass.id === 23 || ass.id === 24 || ass.id === 25
    );
  }else if (match.params.id === "539909e4-56c5-4993-ad4d-e925670c6156") {
    assignmentsToPassChild = assignmentsFromApi?.filter(
      (ass) => ass.id === 26 || ass.id === 27 || ass.id === 28 || ass.id === 29 || ass.id === 30
    );
  }

    else {
    assignmentsToPassChild = assignmentsFromApi?.filter(
      (ass) => ass.id === 1 || ass.id === 2 || ass.id === 3 || ass.id === 4
    );
  }

  console.log(assignmentsToPassChild);

  return (
    <>
      {loading && (
        <>
          <CourseListPlaceholder />
          <CourseListPlaceholder />
        </>
      )}
      <Grid>
        <Grid.Column width={12}>
          <AssignmentList
            courseId={match.params.id}
            assignments={assignmentsToPassChild}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <div>
            <Segment>
              <Header>Question Bank</Header>
              <a
                href={questionBankUrl}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <Button color="teal" content="Download Question Bank" />
              </a>
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
}
