import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { verifyAuth } from '../../features/auth/authActions';
import jwt from 'jwt-decode';

export function ConfigureStore() {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    const token = localStorage.getItem("accessToken")

    if (token == null || token == undefined) {
        store.dispatch(verifyAuth());
    }
    else {
        const user = jwt(token)
        store.dispatch(verifyAuth(user));
    }

    return store;
}