import { FETCH_COURSE, FETCH_COURSES } from "./courseConstants";

const initialState = {
  courses: [],
  course:{}
};

export default function courseReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_COURSES:
      return {
        ...state,
        courses: payload,
      };
      case FETCH_COURSE:
      return {
        ...state,
        course: payload,
      };
    default:
      return state;
  }
}
