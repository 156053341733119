export const roleData = [
    {key: 'admin', text: 'Admin', value: 'admin'},
    {key: 'student', text: 'Student', value: 'student'},
    {key: 'grader', text: 'Grader', value: 'grader'},
    {key: 'reviewer', text: 'Reviewer', value: 'reviewer'}    
];

export const register = [
    {key:'yes', text:'Yes', value:'yes'},
    {key:'no', text:'No', value:'No'}
]