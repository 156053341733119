import React from "react";
import NavBar from "../../features/nav/NavBar";
import { Container } from "semantic-ui-react";
import { Route } from "react-router-dom";
import ModalManager from "../common/modals/ModalManager";
import { ToastContainer } from "react-toastify";
import AccountPage from "../../features/auth/AccountPage";
import { useSelector } from "react-redux";
import LoadingComponent from "./LoadingComponent";
import LandinPage from "../../features/home/LandinPage";
import HomePage from "../../features/home/HomePage";
import CourseDashboard from "../../features/courses/CourseDashboard";
import AssignmentDashboard from "../../features/assignments/AssignmentDashboard";
import IndividualAssignment from "../../features/assignments/IndividualAssignment";
import CreateAssignment from "../../features/assignments/CreateAssignment";
import AllocateAssignments from "../../features/assignments/AllocateAssignments";
import SubmittedAssignments from "../../features/assignments/SubmittedAssignments";
import GradeAssignment from "../../features/assignments/GradeAssignment";
import ReviewAssignment from "../../features/assignments/ReviewAssignment";
import GradesDashboard from "../../features/grades/GradesDashboard";
import GradeFinalAssignment from "../../features/assignments/GradeFinalAssignment";
import GradeVM from "../../features/assignments/GradeVM";
import AddAttendance from "../../features/attendance/AddAttendance";
import EditAttendance from "../../features/attendance/EditAttendance";
import ViewAttendance from "../../features/attendance/ViewAttendance";
import ServiceLog from "../../features/servicelog/ServiceLog";
import { Link, useHistory } from "react-router-dom";
import NewRegistrations from '../../features/home/NewRegistrations';

function App() {
  const { initialized } = useSelector((state) => state.async);

  const history = useHistory();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const token = localStorage.getItem("accessToken");

  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      console.log("Token Expired");
      localStorage.removeItem("accessToken");
      window.location.reload(false);
      history.push("/");
    }
  }

  if (!initialized) return <LoadingComponent content="Loading App" />;

  return (
    <>
      <ModalManager />
      <ToastContainer position="bottom-right" hideProgressBar />
      <Route path="/" exact component={LandinPage} />
      <Route
        path={"/(.+)"}
        render={() => (
          <>
            <NavBar />
            <Container className="main">
              <Route path="/home" exact component={HomePage} />
              <Route path="/account" component={AccountPage} />
              <Route path="/courses" component={CourseDashboard} />
              <Route path="/assignments/:id" component={AssignmentDashboard} />
              <Route
                path="/assignment/:courseId/:assignmentId"
                component={IndividualAssignment}
              />
              <Route path="/createAssignment" component={CreateAssignment} />
              <Route
                path="/submittedAssignments/:courseId/:assignmentId"
                component={SubmittedAssignments}
              />
              <Route
                path="/allocateAssignments/:courseId/:assignmentId"
                component={AllocateAssignments}
              />
              <Route
                path="/gradeAssignment/:courseId/:assignmentId/:studentId"
                component={GradeAssignment}
              />
              <Route
                path="/reviewAssignment/:courseId/:assignmentId/:studentId"
                component={ReviewAssignment}
              />
              <Route
                path="/gradeFinalAssignment/:courseId/:assignmentId/:studentId"
                component={GradeFinalAssignment}
              />
              <Route
                path="/gradeVM/:courseId/:assignmentId/:studentId"
                component={GradeVM}
              />
              <Route path="/grades/:courseId" component={GradesDashboard} />
              <Route path="/addAttendance" component={AddAttendance} />
              <Route path="/editAttendance" component={EditAttendance} />
              <Route path="/viewAttendance" component={ViewAttendance} />
              <Route path="/servicelog" component={ServiceLog} />
              <Route path="/new-registrations" component={NewRegistrations} />
            </Container>
          </>
        )}
      />
    </>
  );
}

export default App;
