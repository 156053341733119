import React, { useState } from "react";
import {
  Grid,
  Header,
  Segment,
  Item,
  Icon,
  Button,
  Label,
  GridColumn,
  Menu,
  Container,
  Dropdown,
  Card,
  Form,
  Table,
  Tab,
} from "semantic-ui-react";
import { fetchModules, fetchModuleStudents, postAttendance } from "../../app/api/apiservice";
import { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { toast } from "react-toastify";

export default function AddAttendance() {
  const { currentUser } = useSelector((state) => state.auth);
  const [modules, setModules] = useState([]);
  const [moduleStudents, setModuleStudents] = useState([]);
  const [formValues, setFormValues] = useState({});

  const history = useHistory();

  useEffect(() => {
    const fetchModulesFn = async () => {
      const result = await fetchModules();

      if (result == null) {
        console.log("Check docs upload error");
      } else {
        let fetchedModules = result.data.data;
        let newArray = fetchedModules.map((obj) => {
          return {
            key: obj.id,
            text: obj.name.split("|")[1],
            value: obj.name.split("|")[0],
          };
        });
        setModules((previousArray) => [...previousArray, ...newArray]);
      }
    };

    fetchModulesFn().catch(console.error);
  }, []);

  const handleSelectChange = async (e, data) => {
    const response = await fetchModuleStudents(data.value);

    if (response.data === null) {
      console.log("fetch module students error");
    } else {
      let fetchedModuleStudents = response.data.data;
      let newArray = fetchedModuleStudents.map((obj) => {
        return {
          key: obj.id,
          text: obj.name.split("|")[1],
          value: obj.name.split("|")[0],
        };
      });

      let someObj = {}
      newArray.map(obj => {        
        someObj[obj.key] = false
      })
      let attendanceObj = {
        attendance: someObj
      }
      setFormValues({
        ...formValues,
        ...attendanceObj,
        [data.name]: data.value,
        markedBy: currentUser?.uid,

      });
      setModuleStudents([...moduleStudents, ...newArray]);
    }
  };

  const onDateChange = async (e, data) => {
    setFormValues({ ...formValues, [data.name]: data.value });
  };

  const handleCheckboxChange = async (e,data) => {
    formValues.attendance[data.name] = data.checked
    setFormValues({...formValues})
  }

  const handleFormSubmit = async () => {
    const response =  await postAttendance(formValues)
    if(response?.data.data === null ||response?.data.data === undefined ){
        toast.error("Error Posting Attendance")
    }
    else{
        toast.success("Attendance posted successfully")
        history.push("/addAttendance")

    }
  }

  return (
    <>
      {currentUser?.role === "teacher" && (
        <Card fluid>
          <Card.Content>
            <Form unstackable>
              <Form.Group width={16}>
                <Form.Select
                  name="moduleId"
                  required
                  label="Select Module"
                  placeholder="Select Module"
                  fluid
                  options={modules}
                  onChange={(e, data) =>
                    handleSelectChange(e, {
                      name: "moduleId",
                      value: data.value,
                    })
                  }
                ></Form.Select>
              </Form.Group>
              <Form.Group>
                <SemanticDatepicker
                required
                  name="date"
                  label="Select attendance date"
                  onChange={onDateChange}
                />
              </Form.Group>
              <Form.Group>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Present</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {moduleStudents?.map((moduleStudent) => {
                      return (
                        <Table.Row key={moduleStudent.key}>
                          <Table.Cell>{moduleStudent.text}</Table.Cell>
                          <Table.Cell>
                            <Form.Checkbox
                                name={moduleStudent.key}
                                onChange={(e, data) => handleCheckboxChange(e,data)}
                            />                            
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Form.Group>
              <Form.Group>
                <Button primary type='submit' onClick = {handleFormSubmit}>Submit</Button>
              </Form.Group>
            </Form>
          </Card.Content>
        </Card>
      )}
    </>
  );
}
