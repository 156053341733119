import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Segment, Item, Button, Label } from "semantic-ui-react";

export default function CourseListItem({ course }) {
  const { currentUser } = useSelector((state) => state.auth);
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Image size='tiny' circular src={course.photoUrl} />
            <Item.Content>
              <Item.Header content={course?.name.split("|")[1]} /><br />
              <Item.Description>{course.description}</Item.Description>
              {course?.isActive ? (
                <Label
                  style={{ top: "-40x" }}
                  ribbon='right'
                  color='green'
                  content='This Course is Active'
                />
              ) : (
                <Label
                  style={{ top: "-40px" }}
                  ribbon='right'
                  color='red'
                  content='This Course is Inactive'
                />
              )}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>

      <Segment clearing>
        {currentUser?.role === "admin" && (
          <Button
            color='teal'
            content='View Grades'
            as={Link}
            to={`/grades/${course.id}`}
          />
        )}
        {course?.courseInfo !== undefined && (
          <a
          href={course.courseInfo}
          target='_blank'
          rel='noopener noreferrer'
          download
        >
          <Button color='teal' floated='left' content='Download Course Info' />
        </a>
        )}
        
        <Button
        disabled = {!course?.viewAssignments}
          color='teal'
          floated='right'
          content='View Assignments'
          as={Link}
          to={`/assignments/${course?.id}`}
        />
      </Segment>
    </Segment.Group>
  );
}
