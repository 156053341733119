import React from "react";
import { useDispatch } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { getUsersFromFirestore } from "../../app/firestore/firestoreService";
import useFirestoreCollection from "../../app/hooks/useFireStoreCollection";
import { getUsers } from "../auth/authActions";

export default function AllocateAssignments({match}) {
    console.log(match.params);
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => getUsersFromFirestore(),
    data: (users) => dispatch(getUsers(users)),
    deps: [dispatch],
  });

  return (
      <Segment>
          <Header content="Allocate ASsignments"/>
      </Segment>
  )
}
