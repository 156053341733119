import React, { useState } from "react";
import {
  Grid,
  Header,
  Segment,
  Item,
  Icon,
  Button,
  Label,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import CourseListPlaceholder from "./CourseListPlaceholder";
import CourseList from "./CourseList";
import {
  listenToCoursesFromFireStore,
  updateCommonFileUrl,
} from "../../app/firestore/firestoreService";
import { listenToCourses } from "./courseActions";
import useFirestoreCollection from "../../app/hooks/useFireStoreCollection";
import { getFileExtension } from "../../app/common/util/util";
import { uploadCommonFileToFirebaseStorage } from "../../app/firestore/firebaseService";
import { toast } from "react-toastify";
import {
  uploadFileUrl,
  checkifdocsuploaded,
  fetchModules,
} from "../../app/api/apiservice";

import FileDropZone from "../FileDrop/FileDropZone";
import { useEffect } from "react";

export default function CourseDashboard() {
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.course);
  const { loading } = useSelector((state) => state.async);
  const { currentUser } = useSelector((state) => state.auth);
  const [modules, setModules] = useState([]);
  const [registrationFile, setRegistrationFile] = useState();
  const [recommendationFile, setrecommendationFile] = useState();
  const [registrationUploaded, setRegistrationUploaded] = useState(false);
  const [recommendationUploaded, setRecommendationUploaded] = useState(false);
  const [fileLoading, setLoading] = useState(false);
  const [apicallresults, setApiCallResults] = useState({
    registrationUploaded: false,
    recommendationUploaded: false,
    modules: [],
  });

  const recommendationDoc =
    "https://firebasestorage.googleapis.com/v0/b/bvadallas-47ef7.appspot.com/o/courses2022%2FRecommendationLetter.doc?alt=media&token=1e697e9a-8025-466d-93bf-98ad1dee4cbe";
  const registrationDoc =
    "https://firebasestorage.googleapis.com/v0/b/bvadallas-47ef7.appspot.com/o/courses2022%2FRegistrationForm.doc?alt=media&token=30306254-5b66-4588-8acd-18cef0d4dd0f";

  // useFirestoreCollection({
  //   query: () => listenToCoursesFromFireStore(),
  //   data: (courses) => dispatch(listenToCourses(courses)),
  //   deps: [dispatch],
  // });

  useEffect(() => {
    const fetchData = async () => {
      const result = await checkifdocsuploaded();     
      
      const moduleResult = await fetchModules()

      if (result == null || moduleResult == null) {
        console.log("Check docs upload error");
      } else {
        if (result.data.data.registrationUploaded == true) {
          setRegistrationUploaded(true);
        }
        if (result.data.data.recommendationUploaded == true) {
          setRecommendationUploaded(true);
        }

        const modulesFromResult = moduleResult.data.data
        console.log(modulesFromResult)
        dispatch(listenToCourses(modulesFromResult))
        //setModules([...modules, modulesFromResult])
      }
    };

    fetchData().catch(console.error);
  }, []);

  function handleRegistrationAdd(uploadedFile) {
    setRegistrationFile(uploadedFile);
  }

  function handleRecommendationAdd(uploadedFile) {
    setrecommendationFile(uploadedFile);
  }

  async function handleUpload(docType) {
    setLoading(true);
    let filename;
    let file;

    if (docType == "registration") {
      const regfileExtension = getFileExtension(registrationFile.name);
      console.log("Registration file extension", regfileExtension);
      if (regfileExtension !== "pdf") {
        setLoading(false);
        toast.error("Please upload only PDF file");
        return;
      }
      file = registrationFile;
      filename = currentUser.uid + "." + getFileExtension(file.name);
    } else {
      const recfileExtension = getFileExtension(recommendationFile.name);
      if (recfileExtension !== "pdf") {
        setLoading(false);
        toast.error("Please upload only PDF file");
        return;
      }
      file = recommendationFile;
      filename = currentUser.uid + "." + getFileExtension(file.name);
    }

    const uploadTask = uploadCommonFileToFirebaseStorage(
      file,
      filename,
      docType
    );
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress} % done`);
      },
      (error) => {
        setLoading(false);
        toast.error(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          uploadFileUrl(downloadUrl, currentUser.uid, docType)
            .then(() => {
              setLoading(false);
              toast.success("Document Submitted Successfully");
            })
            .catch((error) => {
              toast.error(error.message);
              setLoading(false);
            });
        });
      }
    );
  }

  return (
    <Grid>
      <Grid.Column width={10}>
        {loading && (
          <>
            <CourseListPlaceholder />
            <CourseListPlaceholder />
          </>
        )}
        <CourseList courses={courses} />
      </Grid.Column>
      <Grid.Column width={6}>
        {currentUser?.role === "student" && (
          <>
            <Segment.Group>
              <Segment>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header content="Registration" />
                      <Item.Description content="Upload Completed Registration Form here" />
                      {registrationUploaded ? (
                        <Label
                          style={{ top: "-40x" }}
                          ribbon="right"
                          color="green"
                          content="Registration Uploaded"
                        />
                      ) : (
                        <Label
                          style={{ top: "-40px" }}
                          ribbon="right"
                          color="red"
                          content="Upload Registration"
                        />
                      )}
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
              <Segment>
                <a
                  href={registrationDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button color="teal" content="Download Registration Form" />
                </a>
              </Segment>
              <Segment>
                <Segment>
                  <Grid>
                    <Grid.Column width={6}>
                      <Header
                        color="blue"
                        sub
                        content="Select Finished Registration"
                      />
                      <br />
                      <FileDropZone handleAdd={handleRegistrationAdd} />
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={4}>
                      <Header color="blue" sub content="Selected File" />
                      <br />
                      <br />
                      {registrationFile && <Icon name="file" size="huge" />}
                      <Header
                        color="blue"
                        sub
                        content={registrationFile?.name}
                      />
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={4}>
                      {registrationFile && (
                        <Button
                          loading={fileLoading}
                          floated="right"
                          color="green"
                          content="Upload"
                          onClick={() => handleUpload("registration")}
                        />
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header content="Recommendation" />
                      <Item.Description content="Upload Completed Recommendation Form here" />
                      {recommendationUploaded ? (
                        <Label
                          style={{ top: "-40x" }}
                          ribbon="right"
                          color="green"
                          content="Recommendation Uploaded"
                        />
                      ) : (
                        <Label
                          style={{ top: "-40px" }}
                          ribbon="right"
                          color="red"
                          content="Upload Recommendation"
                        />
                      )}
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
              <Segment>
                <a
                  href={recommendationDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button color="teal" content="Download Recommendation Form" />
                </a>
              </Segment>
              <Segment>
                <Segment>
                  <Grid>
                    <Grid.Column width={6}>
                      <Header
                        color="blue"
                        sub
                        content="Select Finished Recommendation"
                      />
                      <br />
                      <FileDropZone handleAdd={handleRecommendationAdd} />
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={4}>
                      <Header color="blue" sub content="Selected File" />
                      <br />
                      <br />
                      {recommendationFile && <Icon name="file" size="huge" />}
                      <Header
                        color="blue"
                        sub
                        content={recommendationFile?.name}
                      />
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={4}>
                      {recommendationFile && (
                        <Button
                          loading={fileLoading}
                          floated="right"
                          color="green"
                          content="Upload"
                          onClick={() => handleUpload("recommendation")}
                        />
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Segment>
            </Segment.Group>
          </>
        )}
      </Grid.Column>
    </Grid>
  );
}
