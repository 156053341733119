import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid, Item, Segment } from "semantic-ui-react";
import { listenToAssignmentFromFireStore } from "../../app/firestore/firestoreService";
import { listenToAssignments } from "./assignmentActions";
import 'draft-js/dist/Draft.css';
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import TextareaAutosize from "react-textarea-autosize";

export default function IndividualAssignment({ match }) {
  const dispatch = useDispatch();
  console.log(match.params)
  const assignment  = useSelector((state) => state.assignment.assignments.find(a=>a.id === match.params.assignmentId));
  const { currentUser } = useSelector((state) => state.auth);
  console.log("CurrentUser:", currentUser);
  console.log("Assignment:", assignment)

  useFirestoreDoc({
    query: () => listenToAssignmentFromFireStore(match.params.courseId, match.params.assignmentId),
    data: (assignment) => dispatch(listenToAssignments([assignment])),
    deps: [dispatch],
  });

  // const assignment = assignments.filter(
  //   (assignment) => assignment.id === match.params.assignmentId
  // );
  // console.log(assignments, assignment);

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header content={assignment?.name} />
                
              </Item.Content>
              
            </Item>
          </Item.Group>
          <Form>
            <TextareaAutosize readOnly value={assignment?.question} />
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column width={6}></Grid.Column>
    </Grid>
  );
}
