import { ADD_ROLE_TO_USER, GET_USERS, SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";
import { APP_LOADED } from "../../app/async/asyncReducer";

import firebase from "../../app/config/firebase";
// import { getUserFromFirestore } from "../../app/firestore/firestoreService";
// import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";

export function signInUser(user) {
  return {
    type: SIGN_IN_USER,
    payload: user,
  };
}

export function signOutUser() {

  return {
    type: SIGN_OUT_USER,
  };
}

export function addRoleToUser(role) {
  return {
    type: ADD_ROLE_TO_USER,
    payload: role
  }
}

export function verifyAuth(loginResponse) {
  return function (dispatch) {
    if (loginResponse != null || loginResponse != undefined) {
      dispatch(signInUser(loginResponse));
      dispatch({ type: APP_LOADED });
    } else {
      dispatch(signOutUser());
      dispatch({ type: APP_LOADED });
    }
    ;
  };
}


// export function verifyAuth(loginResponse) {
//   return function (dispatch) {
//     return firebase.auth().onAuthStateChanged((user) => {
//       if (user) {        
//         dispatch(signInUser(user));
//         dispatch({ type: APP_LOADED });
//       } else {
//         dispatch(signOutUser());
//         dispatch({ type: APP_LOADED });
//       }
//     });
//   };
// }

export function getUsers(users) {
  return {
    type: GET_USERS,
    payload: users
  }
}
