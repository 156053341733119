import axios from "axios";
import { verifyAuth } from "../../features/auth/authActions";
import { ConfigureStore } from "../store/configureStore";

const baseUrl = "https://bvaapi.azurewebsites.net";

//const baseUrl = "https://localhost:7272";

export async function signInUser(values) {
  const response = await axios.post(`${baseUrl}/api/Auth/login`, values);
  localStorage.setItem("accessToken", response.data.data.token);
  const store = ConfigureStore();
  window.location.reload(false);
}

export async function fetchServiceLog(userId, moduleId) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.get(
      `${baseUrl}/api/servicelog/servicelog?userId=${userId}&moduleId=${moduleId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function postServiceLog(values) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      userId: values.userId,
      moduleId: values.moduleId,
      supervisor: values.supervisor,
      description: values.description,
      hours: values.hours,
    };

    console.log(body);

    const response = await axios.post(
      `${baseUrl}/api/servicelog/servicelog`,
      body,
      config
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function changePassword(values) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/Auth/changepassword`,
      values
    );
    localStorage.setItem("accessToken", response.data.data.token);
    const store = ConfigureStore();
    window.location.reload(false);
  } catch (error) {
    throw error;
  }
}

export async function uploadFileUrl(downloadUrl, userId, doctype) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body;

    if (doctype === "registration") {
      body = {
        RegistrationDoc: downloadUrl,
      };

      console.log(body);

      const response = await axios.post(
        `${baseUrl}/api/auth/uploadregistrationdoc`,
        body,
        config
      );
    } else {
      body = {
        RecommendationDoc: downloadUrl,
      };
      const response = await axios.post(
        `${baseUrl}/api/auth/uploadrecommendationdoc`,
        body,
        config
      );
    }
  } catch (error) {
    throw error;
  }
}

export async function FetchAssignedEssays(moduleId, assignmentId, graderId) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      moduleId,
      assignmentId,
      graderId,
    };

    const response = await axios.post(
      `${baseUrl}/api/module/fetchassignedessays`,
      body,
      config
    );

    return response;
  } catch (error) {
    return null;
  }
}

export async function FetchNotAssignedEssays(moduleId, assignmentId, graderId) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      moduleId,
      assignmentId,
      graderId,
    };

    const response = await axios.post(
      `${baseUrl}/api/module/fetchnotassignedessays`,
      body,
      config
    );

    return response;
  } catch (error) {
    return null;
  }
}

export async function FetchMarkingKeys() {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.get(
      `${baseUrl}/api/module/fetchmarkingkeys`,
      config
    );
    return response;
  } catch (error) {
    return null;
  }
}

export async function updateSubmittedAssignment(
  moduleId,
  assignmentId,
  values,
  grade,
  userId
) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      moduleId,
      assignmentId,
      grade,
      userId,
      comments: values.comments,
      mk1: values.mk1,
      mk2: values.mk2,
      mk3: values.mk3,
      mk4: values.mk4,
      mk1Num: values.mk1Num,
      mk2Num: values.mk2Num,
      mk3Num: values.mk3Num,
      mk4Num: values.mk4Num,
    };

    const response = await axios.post(
      `${baseUrl}/api/module/updatesubmittedassignment`,
      body,
      config
    );

    return response;
  } catch (error) {
    return null;
  }
}

export async function updateSubmittedFinalAssignment(
  moduleId,
  assignmentId,
  values,
  grade,
  userId
) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      moduleId,
      assignmentId,
      grade,
      userId,
      comments: values.comments,
      mk1: values.mk1,
      mk2: values.mk2,
      mk3: values.mk3,
      mk1Num: values.mk1Num,
      mk2Num: values.mk2Num,
      mk3Num: values.mk3Num,
    };

    const response = await axios.post(
      `${baseUrl}/api/module/updatesubmittedfinalassignment`,
      body,
      config
    );

    return response;
  } catch (error) {
    return null;
  }
}

export async function FetchSubmittedAssignment(moduleId, assignmentId, userId) {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      moduleId,
      assignmentId,
      userId,
    };

    const response = await axios.post(
      `${baseUrl}/api/module/fetchsubmittedassignment`,
      body,
      config
    );

    return response;
  } catch (error) {
    return null;
  }
}

export async function checkifdocsuploaded() {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${baseUrl}/api/auth/checkifdocsuploaded`,
      config
    );
    return response;
  } catch (error) {
    return null;
  }
}

export async function fetchModules() {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${baseUrl}/api/module/fetchmodules`,
      config
    );
    return response;
  } catch (error) {
    return null;
  }
}

export async function fetchAssignments() {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${baseUrl}/api/module/fetchassignments`,
      config
    );
    return response;
  } catch (error) {
    return null;
  }
}

export async function fetchModuleStudents(moduleId) {
  try {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${baseUrl}/api/module/fetchmodulestudents?moduleId=${moduleId}`,
      config
    );
    return response;
  } catch (error) {
    return null;
  }
}

export async function postAttendance(formValues) {
  try {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.post(
      `${baseUrl}/api/module/attendance`,
      formValues,
      config
    );
    return response;
  } catch (error) {
    return null;
  }
}

export async function updateSubmittedFileUrlApi(
  fileUrl,
  moduleId,
  assignmentId,
  userId
) {
  try {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const submitAssignmentRequest = {
      assignmentId,
      userId,
      moduleId,
      fileUrl,
    };
    const response = await axios.post(
      `${baseUrl}/api/module/submitassignment`,
      submitAssignmentRequest,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function checkSubmission(moduleId, assignmentId, userId) {
  try {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const checkSubmissionRequest = {
      assignmentId,
      userId,
      moduleId,
    };
    const response = await axios.post(
      `${baseUrl}/api/module/checksubmission`,
      checkSubmissionRequest,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
}
