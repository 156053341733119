import { Form, Formik} from "formik";
import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import TextInput from "../../app/common/form/TextInput";
import { Button, Label } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { openModal } from "../../app/common/modals/modalReducer";
import {signInUser} from '../../app/api/apiservice';

export default function LoginForm() {
  const dispatch = useDispatch();
  return (
    <ModalWrapper size='mini' header='Sign In'>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            console.log(values)
            await signInUser(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({auth: "Problem with Username or Password"})
            setSubmitting(false);
            
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <TextInput name='email' placeholder='Email Address' />
            <TextInput
              name='password'
              placeholder='Email Password'
              type='password'
            />
            {errors.auth && <Label basic color='red' style={{marginBottom:10}} content={errors.auth} />}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluid
              size='large'
              color='teal'
              content='Login'
            /><br/>
            <Button
        onClick={() => dispatch(openModal({ modalType: "ForgotPasswordForm" }))}
        type='submit'
              fluid
              size='large'
              color='teal'
        content='Forgot Password'
      />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
