/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Label,
  Segment,
  SegmentGroup,
} from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import TextArea from "../../app/common/form/TextArea";
import * as Yup from "yup";
import {
    addToVM,
  listenToAssignmentFromFireStore
} from "../../app/firestore/firestoreService";
import { useHistory } from "react-router-dom";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { useDispatch, useSelector } from "react-redux";
import { listenToAssignment } from "./assignmentActions";

export default function GradeVM({ match }) {
  const dispatch = useDispatch();
  const { studentId, courseId, assignmentId } = match.params;
  const history = useHistory();
  const assignment = useSelector((state) => state.assignment.assignment);
  const submittedAssignment = assignment?.submittedAssignments?.find(
    (assignment) => assignment?.userId === studentId
  );
  const { currentUser } = useSelector((state) => state.auth);

  const mKeys = assignment?.mk;

  useFirestoreDoc({
    query: () =>
      listenToAssignmentFromFireStore(
        match.params.courseId,
        match.params.assignmentId
      ),
    data: (assignment) => dispatch(listenToAssignment(assignment)),
    deps: [dispatch],
  });

    const initialValues =
    submittedAssignment !== undefined
      ? {
          vmComments: submittedAssignment?.vmComments,
          mk1: mKeys ? mKeys[0] : null,
          mk1Num: submittedAssignment?.markingKey?.mk1?.score
        }
      : {          
          vmComments: "",
          mk1: "",
          mk1Num: 0          
        };

  return (
    <SegmentGroup>
      <Segment>
        <Header content='Grade Verse Memorisation' />
        <a
          href={submittedAssignment?.submittedFile}
          target='_blank'
          rel='noopener noreferrer'
          download
        >
          <Button color='teal' content='Download Submitted Verse Memorisation' />
        </a>
      </Segment>
      <Segment>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            vmComments: Yup.string().required(),
            mk1Num: Yup.number().positive().required().max(150)
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              await addToVM(
                courseId,
                assignmentId,
                values,
                studentId,
                currentUser?.displayName
              );
              setSubmitting(false);
              history.push(`/submittedAssignments/${courseId}/${assignmentId}`);
            } catch (error) {
              setErrors({ err: error.message });
            }
          }}
        >
          {({ isSubmitting, submitForm, isValid, errors }) => (
            <Form className='ui form'>
              <br />
              {mKeys?.length > 0 && (
                <>
                  <Grid>
                    <Grid.Column width={14}>
                      <TextInput
                        name='mk1'
                        id={1}
                        value={mKeys ? mKeys[0] : null}
                      />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <TextInput name='mk1Num' type='number' />
                    </Grid.Column>
                  </Grid>
                  <TextArea
                    name='vmComments'
                    placeholder='Enter Comments for Verse Memorisation Here'
                    rows={10}
                  />                  
                </>
              )}

              {errors.err && (
                <Label
                  basic
                  color='red'
                  style={{ marginBottom: 10 }}
                  content={errors.err}
                />
              )}
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                type='submit'
                fluidsize='large'
                color='teal'
                content='Submit'
                onClick={submitForm}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </SegmentGroup>
  );
}
