import {combineReducers} from 'redux';
import assignmentReducer from '../../features/assignments/assignmentReducer';
import authReducer from '../../features/auth/authReducer';
import courseReducer from '../../features/courses/courseReducer';
import asyncReducer from '../async/asyncReducer';
import modalReducer from '../common/modals/modalReducer';
import userReducer from '../common/users/userReducer';

const rootReducer = combineReducers({
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    course: courseReducer,
    assignment: assignmentReducer,
    user: userReducer
})

export default rootReducer;