import { FETCH_ASSIGNMENT, FETCH_ASSIGNMENTS } from "./assignmentConstants";

const initialState = {
  assignments: [],
  assignment:{}
};

export default function assignmentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ASSIGNMENTS:
      return {
        ...state,
        assignments: payload,
      };
      case FETCH_ASSIGNMENT:
        return{
          ...state,
          assignment: payload
        }
    default:
      return state;
  }
}
