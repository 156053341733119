import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Label,
  Segment,
  SegmentGroup,
} from "semantic-ui-react";
import {
  FetchAssignedEssays,
  FetchNotAssignedEssays,
} from "../../app/api/apiservice";
import { listenToAssignmentFromFireStore } from "../../app/firestore/firestoreService";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { listenToAssignment } from "./assignmentActions";

export default function SubmittedAssignments({ match }) {
  const { courseId, assignmentId } = match.params;
  const dispatch = useDispatch();
  const assignment = useSelector((state) => state.assignment.assignment);
  const { currentUser } = useSelector((state) => state.auth);

  const [fetchAssignedEssaysResponse, setFetchAssignedEssaysResponse] =
    useState({});

  const [fetchNotAssignedEssaysResponse, setFetchNotAssignedEssaysResponse] =
    useState({});

  // useFirestoreDoc({
  //   query: () => listenToAssignmentFromFireStore(courseId, assignmentId),
  //   data: (assignment) => dispatch(listenToAssignment(assignment)),
  //   deps: [dispatch],
  // });

  useEffect(() => {
    const fetchData = async () => {
      const result = await FetchAssignedEssays(
        courseId,
        assignmentId,
        currentUser.uid
      );

      if (result == null) {
        console.log("Error");
      } else {
       
        setFetchAssignedEssaysResponse(result.data.data);
      }

      const notassigned = await FetchNotAssignedEssays(
        courseId,
        assignmentId,
        currentUser.uid
      );


      if (result == null) {
        console.log("Error");
      } else {
        setFetchNotAssignedEssaysResponse(notassigned.data.data);
      }
    };

    fetchData().catch(console.error);
  }, []);

  // var grader = assignment?.graders?.filter(
  //   (grader) => grader.graderId === currentUser?.uid
  // );
  let grader = fetchAssignedEssaysResponse?.grader;
  let submittedAssignmentsFromResponse =
    fetchAssignedEssaysResponse?.submittedAssignments;
  var allStudents = assignment?.submittedAssignments?.map((ass) => ass.userId);

  var reviewer = assignment?.reviewers?.filter(
    (reviewer) => reviewer.reviewerId === currentUser?.uid
  );

  let allottedStudents;

  if (currentUser?.role === "grader" || currentUser?.role === "teacher") {
    //allottedStudents = grader?.length > 0 ? grader[0].allottedStudents : null;
    allottedStudents = grader?.allottedStudents;
    // } else if (
    //   currentUser?.role === "reviewer" &&
    //   assignment?.isFinalTest === true
    // ) {
    //   allottedStudents = grader?.length > 0 ? grader[0].allottedStudents : null;
    // } else if (currentUser?.role === "reviewer" && assignment?.isVM === true) {
    //   allottedStudents = grader?.length > 0 ? grader[0].allottedStudents : null;
    // } else if (currentUser?.role === "reviewer") {
    //   allottedStudents =
    //     reviewer?.length > 0 ? reviewer[0].allottedStudents : null;
    // }

    // var otherStudents = allStudents?.filter(
    //   (student) => !allottedStudents?.includes(student)
    // );

    var otherStudents = fetchNotAssignedEssaysResponse?.submittedAssignments;
    

    return (
      <>
        <Header as="h2">Allotted Students:</Header>
        {fetchAssignedEssaysResponse.isFinalTest === false ||
        assignment?.isVM === false
          ? allottedStudents?.map((student) => {
              const submittedAssignment = submittedAssignmentsFromResponse.find(
                (assignment) => assignment.userId === student
              );
              const isGraded = submittedAssignment?.isGraded;

              const isReviewed = submittedAssignment?.isReviewed;

              return (
                <SegmentGroup key={student}>
                  <Segment>
                    <Grid>
                      <Grid.Column width={12}>
                        <Header content={student} />
                        <Label
                          style={{ top: "-40x" }}
                          ribbon
                          color={isGraded ? "green" : "red"}
                          content={
                            isGraded
                              ? "This has been graded"
                              : "This has not been graded"
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        {currentUser?.role === "grader" && (
                          <Button
                            as={Link}
                            to={`/gradeAssignment/${courseId}/${assignmentId}/${student}`}
                            // disabled={
                            //   fetchAssignedEssaysResponse?.publishGrades ||
                            //   isReviewed
                            // }
                            color="teal"
                            content="Grade Assignment"
                          />
                        )}
                        {currentUser?.role === "reviewer" && (
                          <Button
                            as={Link}
                            to={`/reviewAssignment/${courseId}/${assignmentId}/${student}`}
                            disabled={
                              fetchAssignedEssaysResponse?.publishGrades ||
                              !isGraded
                            }
                            color="teal"
                            content="Review Assignment"
                          />
                        )}
                        <Label
                          style={{ top: "-50x", marginTop: "10px" }}
                          ribbon="right"
                          color={isReviewed ? "green" : "red"}
                          content={
                            isReviewed
                              ? "This has been reviewed"
                              : "This has not been reviewed"
                          }
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </SegmentGroup>
              );
            })
          : allottedStudents?.map((student) => {
              const submittedAssignment =
                submittedAssignmentsFromResponse?.find(
                  (assignment) => assignment.userId === student
                );
              const isGraded = submittedAssignment?.isGraded;

              return (
                <SegmentGroup key={student}>
                  <Segment>
                    <Grid>
                      <Grid.Column width={12}>
                        <Header content={student} />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        {(currentUser?.role === "grader" ||
                          currentUser?.role === "teacher") &&
                        fetchAssignedEssaysResponse?.isFinalTest ? (
                          <Button
                            as={Link}
                            to={`/gradeFinalAssignment/${courseId}/${assignmentId}/${student}`}
                            disabled={
                              fetchAssignedEssaysResponse?.publishGrades
                            }
                            color="teal"
                            content="Grade Assignment"
                          />
                        ) : (
                          <Button
                            as={Link}
                            to={`/gradeVM/${courseId}/${assignmentId}/${student}`}
                            // disabled={assignment?.publishGrades}
                            color="teal"
                            content="Grade Assignment"
                          />
                        )}
                        <Label
                          style={{ top: "-50x", marginTop: "10px" }}
                          ribbon="right"
                          color={isGraded ? "green" : "red"}
                          content={
                            isGraded
                              ? "This has been graded"
                              : "This has not been graded"
                          }
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </SegmentGroup>
              );
            })}

        <Header as="h2">Other Students:</Header>
        {currentUser?.role === "grader" &&
        fetchAssignedEssaysResponse?.isFinalTest === false
          ? otherStudents?.map((submission) => {
              // const submittedAssignment = assignment?.submittedAssignments.find(
              //   (assignment) => assignment.userId === student
              // );
             
              const student = submission?.userId;
              const isGraded = submission?.isGraded;

              return (
                <SegmentGroup key={student}>
                  <Segment>
                    <Grid>
                      <Grid.Column width={12}>
                        <Header content={student} />
                        <Label
                          style={{ top: "-40x" }}
                          ribbon
                          color={isGraded ? "green" : "red"}
                          content={
                            isGraded
                              ? "This has been graded"
                              : "This has not been graded"
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button
                          as={Link}
                          to={
                            currentUser?.role === "grader"
                              ? `/gradeAssignment/${courseId}/${assignmentId}/${student}`
                              : `/reviewAssignment/${courseId}/${assignmentId}/${student}`
                          }
                          disabled={assignment?.publishGrades}
                          color="teal"
                          content="Review Assignment"
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </SegmentGroup>
              );
            })
          : ""}
      </>
    );
  }
}
