import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCffv0irpPE-4vdP3oly649OXcztndT3dc",
  authDomain: "bvadallas-47ef7.firebaseapp.com",
  databaseURL: "https://bvadallas-47ef7.firebaseio.com",
  projectId: "bvadallas-47ef7",
  storageBucket: "bvadallas-47ef7.appspot.com",
  messagingSenderId: "346121456536",
  appId: "1:346121456536:web:74b957c4eff81462a179ed",
  measurementId: "G-LS0V32NSLV",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
