import { format } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Segment,
  Item,
  Button,
  Label,
  Icon,
  Grid,
  Header,
  TextArea,
  Form,
  Input,
} from "semantic-ui-react";
import { getFileExtension } from "../../app/common/util/util";
import { uploadFileToFirebaseStorage } from "../../app/firestore/firebaseService";
import { getDownloadURL } from "firebase/storage";
import {
  listenToAssignmentFromFireStore,
  listenToAssignmentsFromFireStore,
  publishGradesForAssignment,
  updateSubmittedFileUrl,
  updateFinalAssignment,
} from "../../app/firestore/firestoreService";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import useFirestoreCollection from "../../app/hooks/useFireStoreCollection";
import FileDropZone from "../FileDrop/FileDropZone";
import { listenToAssignment, listenToAssignments } from "./assignmentActions";
import {
  checkSubmission,
  FetchSubmittedAssignment,
  updateSubmittedFileUrlApi,
} from "../../app/api/apiservice";
import { useEffect } from "react";

export default function AssignmentListItem({ assignment, courseId }) {
  const dispatch = useDispatch();

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const formattedDate = new Date(
    assignment?.lastDateForSubmission
  ).toLocaleDateString(undefined, dateOptions);

  // useFirestoreDoc({
  //   query: () => listenToAssignmentFromFireStore(courseId, assignment.id),
  //   data: (assignment) => dispatch(listenToAssignment(assignment)),
  //   deps: [dispatch],
  // });

  // useFirestoreCollection({
  //   query: () => listenToAssignmentsFromFireStore(courseId),
  //   data: (assignments) => dispatch(listenToAssignments(assignments)),
  //   deps: [dispatch],
  // });

  const { currentUser } = useSelector((state) => state.auth);
  const { assignments } = useSelector((state) => state.assignment);

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState({
    isError: true,
    error: "",
  });
  const [isAssignmentSubmitted, setIsAssignmentSubmitted] = useState(false);
  const [mySubmittedAssignment, setMySubmittedAssignment] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await checkSubmission(
        courseId,
        assignment.id,
        currentUser.uid
      );

      if (result.data.data == false) {
        setIsAssignmentSubmitted(false);
      } else {
        console.log(result.data.data);
        setIsAssignmentSubmitted(true);
      }

      const assignmentResult = await FetchSubmittedAssignment(
        courseId,
        assignment.id,
        currentUser.uid
      );

      if (assignmentResult === null || assignmentResult === undefined) {
        console.log("Error");
      } else {
        setMySubmittedAssignment(assignmentResult.data.data);
      }
    };

    fetchData().catch(console.error);
  }, []);

  console.log(mySubmittedAssignment);

  const assignmentFromState = useSelector((state) =>
    state.assignment?.assignments?.find((ass) => ass.id === assignment?.id)
  );

  var attendance = assignment?.attendance;

  var submittedAssignments1 = assignments?.filter(
    (x) => x.id === "assignment1"
  )[0]?.submittedAssignments;
  var submittedAssignments2 = assignments?.filter(
    (x) => x.id === "assignment2"
  )[0]?.submittedAssignments;
  var submittedAssignments3 = assignments?.filter(
    (x) => x.id === "assignment3"
  )[0]?.submittedAssignments;
  var submittedVm1 = assignments?.filter((x) => x.id === "vm1")[0]
    ?.submittedAssignments;

  const myAssignment = assignmentFromState?.submittedAssignments?.find(
    (assignment) => assignment?.userId === currentUser?.uid
  );

  function handleAdd(uploadedFile) {
    setFile(uploadedFile);
  }

  async function handleUpload() {
    setLoading(true);
    const filename = currentUser.uid + "." + getFileExtension(file.name);

    const fileext = getFileExtension(file.name);

    if (assignment?.isFinalTest === true) {
      if (fileext === "pdf") {
      } else {
        toast.error("Upload only '.pdf' Files for final test");
        setLoading(false);
        return;
      }
    } else {
      if (fileext === "docx") {
      } else {
        toast.error("Upload only '.docx'  Files");
        setLoading(false);
        return;
      }
    }

    if (assignment?.isEssay === true) {
      let formData = new FormData();
      formData.append("file", file);
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      const response = await fetch(
        "https://us-central1-bvadallas-47ef7.cloudfunctions.net/wordCount",
        requestOptions
      );
      console.log("Response: ", response);
      if (response.status === 500) {
        const data = await response.json();
        toast.error(data.error);
        setLoading(false);
        return;
      }
      const data = await response.json();
      if (data.wordCount > 550) {
        toast.error("Essay has more than 500 words. Please make it shorter");
        setFileError({
          isError: true,
          error: "Essay has more than 500 words. Please make it shorter",
        });
        setLoading(false);
        return;
      }
      setFileError({
        isError: false,
        error: "Essay has less than 500 words. Accepted",
      });
    }

    const uploadTask = uploadFileToFirebaseStorage(
      file,
      filename,
      courseId,
      assignment.id
    );
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress} % done`);
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          updateSubmittedFileUrlApi(
            downloadUrl,
            courseId,
            assignment.id,
            currentUser.uid
          )
            .then(() => {
              setLoading(false);
              toast.success("Assignment Submitted Successfully");
            })
            .catch((error) => {
              toast.error(error.message);
              setLoading(false);
            });
        });
      }
    );
  }

  function handlePublish() {
    publishGradesForAssignment(courseId, assignment?.id);
  }

  async function handlePublishFinalGrades() {
    var finalSubmittedAssignments = assignmentFromState?.submittedAssignments;

    for (var finalAssignment of finalSubmittedAssignments) {
      var studentId = finalAssignment?.userId;

      var assignment1Grade = submittedAssignments1?.filter(
        (x) => x.userId === studentId
      )[0].grade;
      var assignment2Grade = submittedAssignments2?.filter(
        (x) => x.userId === studentId
      )[0].grade;
      var assignment3Grade = submittedAssignments3?.filter(
        (x) => x.userId === studentId
      )[0].grade;
      var attendanceGrade = attendance?.filter((x) => x.id === studentId)[0]
        .score;
      // var vm1Grade = submittedVm1.filter((x) => x.userId === studentId)[0]
      //   .grade;
      // var vm2Grade = assignmentFromState?.submittedAssignments?.filter(
      //   (x) => x.userId === studentId
      // )[0].markingKey?.mk2?.score;
      // var vmGrade = vm1Grade + vm2Grade;
      var vmGrade = assignmentFromState?.submittedAssignments?.filter(
        (x) => x.userId === studentId
      )[0].markingKey?.mk2?.score;
      var ftSaqGrade = assignmentFromState?.submittedAssignments?.filter(
        (x) => x.userId === studentId
      )[0].markingKey?.mk1?.score;
      var totalFinalGrade =
        (0.4 *
          ((assignment1Grade + assignment2Grade + assignment3Grade) * 100)) /
          60 +
        0.5 * ((0.6 * (ftSaqGrade * 100)) / 65 + 0.4 * ((vmGrade * 100) / 40)) +
        attendanceGrade;

      var grade = totalFinalGrade;

      var assignment1 = {
        grade: assignment1Grade,
        pass: assignment1Grade > 0.6 * 20 ? true : false,
      };

      var assignment2 = {
        grade: assignment2Grade,
        pass: assignment2Grade > 0.6 * 20 ? true : false,
      };

      var assignment3 = {
        grade: assignment3Grade,
        pass: assignment3Grade > 0.6 * 20 ? true : false,
      };

      var vm = {
        grade: vmGrade,
        pass: vmGrade > 0.65 * 40 ? true : false,
      };

      var ftSaq = {
        grade: ftSaqGrade,
        pass: ftSaqGrade > 0.65 * 65 ? true : false,
      };

      var pass =
        totalFinalGrade > 70 &&
        assignment1?.pass === true &&
        assignment2?.pass === true &&
        assignment3?.pass === true &&
        vm?.pass === true &&
        ftSaq?.pass === true
          ? true
          : false;

      await updateFinalAssignment(
        courseId,
        assignment?.id,
        studentId,
        assignment1,
        assignment2,
        assignment3,
        vm,
        ftSaq,
        grade,
        pass
      );
    }

    handlePublish();
  }

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header content={assignment.name} />
              <Item.Description content={assignment.summary} />
              {assignment?.isActive ? (
                <Label
                  style={{ top: "-30px" }}
                  ribbon="right"
                  color="green"
                  content="This Assignment is Active: Upload Files Enabled"
                />
              ) : (
                <Label
                  style={{ top: "-50px" }}
                  ribbon="right"
                  color="red"
                  content="This Assignment is Inactive: Upload Files Disabled"
                />
              )}
            </Item.Content>
          </Item>
          <Item>
            {fileError?.isError ? (
              <Label
                style={{ top: "-30px" }}
                ribbon="right"
                color="red"
                content={fileError?.error}
              />
            ) : (
              <Label
                style={{ top: "-30px" }}
                ribbon="right"
                color="green"
                content={fileError?.error}
              />
            )}
          </Item>
          {currentUser?.role === "student" && (
            <Item>
              <Item.Content>
                {isAssignmentSubmitted ? (
                  <Label
                    style={{ top: "0px" }}
                    ribbon="right"
                    color="green"
                    content="Assignment Submitted"
                  />
                ) : (
                  <Label
                    style={{ top: "0px" }}
                    ribbon="right"
                    color="red"
                    content="Assignment Not Submitted"
                  />
                )}
              </Item.Content>
            </Item>
          )}

          <Item>
            <Item.Content>
              {assignment?.publishGrades ? (
                <Label
                  style={{ top: "0px" }}
                  ribbon="right"
                  color="green"
                  content="Grades have been published"
                />
              ) : (
                <Label
                  style={{ top: "0px" }}
                  ribbon="right"
                  color="red"
                  content="Grades have not been published"
                />
              )}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <span>
          <Icon name="clock" /> Last Date For Submission: {formattedDate}
          {/* {format(assignment.lastDateForSubmission, "MMMM d, yyyy h:mm a")} */}
        </span>
      </Segment>
      <Segment clearing>
        {assignment?.isFinalTest ? (
          <></>
        ) : (
          <div>
            <Label size="large" color="teal" content="Total Grade: " />{" "}
            <Label
              size="large"
              color="green"
              content={assignment?.totalGrade}
            />
          </div>
        )}

        <br />
        <a
          href={assignment.question}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <Button
            color="teal"
            disabled={!assignment?.isActive}
            content="Download Question"
          />
        </a>
        {currentUser?.role === "student" &&
          assignment?.publishGrades &&
          assignment?.isFinalTest === true &&
          mySubmittedAssignment && (
            <Segment.Group>
              <Segment>
                <Label
                  size="large"
                  color="teal"
                  content="Final Module Grade: "
                />{" "}
                <Label
                  size="large"
                  color="green"
                  content={`${mySubmittedAssignment?.totalGrade}`}
                />
              </Segment>
              <Segment>
                <Label size="large" color="teal" content="Result: " />{" "}
                <Label
                  size="large"
                  color={mySubmittedAssignment?.pass ? "green" : "red"}
                  content={mySubmittedAssignment?.pass ? "Pass" : "Please Contact Hemangi Mataji"}
                />
              </Segment>
              <Segment>
                <Form>
                  <TextArea value={mySubmittedAssignment?.comments} readOnly />
                </Form>
              </Segment>
            </Segment.Group>
          )}
        {(currentUser?.role === "grader" ||
          currentUser?.role === "teacher") && (
          <Button
            disabled={assignmentFromState?.isActive}
            color="teal"
            floated="right"
            content="View Assignments"
            as={Link}
            to={`/submittedAssignments/${courseId}/${assignment.id}`}
          />
        )}
        {currentUser?.role === "admin" && (
          <>
            {assignmentFromState?.isFinalTest === true ? (
              <Button
                color="teal"
                floated="right"
                content={
                  assignmentFromState?.publishGrades
                    ? "Unpublish Final Grades"
                    : "Publish Final Grades"
                }
                onClick={handlePublishFinalGrades}
              />
            ) : (
              <Button
                color="teal"
                floated="right"
                content={
                  assignmentFromState?.publishGrades
                    ? "Unpublish Grades"
                    : "Publish Grades"
                }
                onClick={handlePublish}
              />
            )}
          </>
        )}
      </Segment>
      {currentUser?.role === "student" && assignment?.isActive && (
        <Segment>
          <Grid>
            <Grid.Column width={6}>
              <Header color="blue" sub content="Select Finished Assignment" />
              <br />
              <FileDropZone handleAdd={handleAdd} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header color="blue" sub content="Selected File" />
              <br />
              <br />
              {file && <Icon name="file" size="huge" />}
              <Header color="blue" sub content={file?.name} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              {file && (
                <Button
                  loading={loading}
                  floated="right"
                  color="green"
                  content="Upload"
                  onClick={handleUpload}
                />
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      )}
      {currentUser?.role === "student" &&
        assignment?.publishGrades &&
        assignment?.isFinalTest !== true &&
        mySubmittedAssignment && (
          <Segment>
            <Form className="ui form">
              <Label size="large" color="teal" content="Comments: " />
              <br />
              <br />
              <TextArea
                value={mySubmittedAssignment?.comments}
                rows={10}
                readOnly
              />
              <br />
              <br />
              <div>
                <Label size="large" color="teal" content="Awarded Grade: " />{" "}
                <Label
                  size="large"
                  color="green"
                  content={mySubmittedAssignment?.grade}
                />
              </div>
              <br />
              <Label size="large" color="teal" content="Marking Key: " />
              <Grid>
                <Grid.Column width={14}>
                  <Input
                    fluid
                    name="mk1"
                    value={mySubmittedAssignment?.mk1}
                    readOnly
                  />
                  <Input
                    fluid
                    name="mk2"
                    value={mySubmittedAssignment?.mk2}
                    readOnly
                  />
                  <Input
                    fluid
                    name="mk3"
                    value={mySubmittedAssignment?.mk3}
                    readOnly
                  />
                  <Input
                    fluid
                    name="mk4"
                    value={mySubmittedAssignment?.mk4}
                    readOnly
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Input
                    name="mk1Num"
                    type="number"
                    value={mySubmittedAssignment?.mk1Num}
                    readOnly
                  />
                  <Input
                    name="mk2Num"
                    type="number"
                    value={mySubmittedAssignment?.mk2Num}
                    readOnly
                  />
                  <Input
                    name="mk3Num"
                    type="number"
                    value={mySubmittedAssignment?.mk3Num}
                    readOnly
                  />
                  <Input
                    name="mk4Num"
                    type="number"
                    value={mySubmittedAssignment?.mk4Num}
                    readOnly
                  />
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
        )}
    </Segment.Group>
  );
}
