import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Container, Dropdown } from "semantic-ui-react";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";
import logoImage from '../../assets/logo.jpg';

export default function NavBar({ setFormOpen }) {
  const { currentUser, authenticated } = useSelector((state) => state.auth);

  return (
    <Menu inverted fixed="top">
      <Container>
        <Menu.Item as={NavLink} exact to="/" header>
          <img
            src={logoImage}
            alt="logo"
            style={{ marginRight: "15px" }}
          />
          Bhaktivedanta Vedic Academy
        </Menu.Item>

        {authenticated && (
          <Menu.Item name="Courses" as={NavLink} to="/courses" />
        )}

        {authenticated && currentUser?.role === "student" &&(
          <Menu.Item name="Service Log" as={NavLink} to="/servicelog" />
        )}

{authenticated && currentUser?.role === "admin" && (
          <Menu.Item name="New Registrations" as={NavLink} to="/new-registrations" />
        )}

        {/* {authenticated && currentUser.role === "admin" && (
          <Menu.Item
            name="Create Assignment"
            as={NavLink}
            to="/createAssignment"
          />
        )} */}

        {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
      </Container>
    </Menu>
  );
}
