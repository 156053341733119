import { ADD_ROLE_TO_USER, GET_USERS, SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";

const initialState = {
  authenticated: false,
  currentUser: null,
  users: []
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: {
          email: payload.email,
          //photoURL: payload.photoURL,
          uid: payload.uid,
          displayName: payload.name,
          role: payload.role
          //providerId: payload.providerData[0].providerId,
        },
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    case ADD_ROLE_TO_USER:
      return {
        ...state,
        ...state.currentUser.role = payload
      }
    case GET_USERS:
      return {
        ...state,
        users: payload
      }
    default:
      return state;
  }
}
