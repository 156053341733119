import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Header,
  Form,
  Button,
  Grid,
  Segment,
  Message,
  Checkbox,
  Icon,
  Modal,
  Label
} from 'semantic-ui-react';
import { addRegistrationToFirestore, checkIfEmailRegistered } from '../../app/firestore/firestoreService';
import firebase from '../../app/config/firebase';

export default function HomePage() {
    const { currentUser } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    initiatedName: '',
    email: '',
    phoneNumber: '',
    reason: '',
    recommendingDevotee: '',
  });
  const [guidelinesRead, setGuidelinesRead] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        return value.trim() ? null : `${name === 'firstName' ? 'First' : 'Last'} name is required`;
      case 'email':
        return value.trim() ? (/\S+@\S+\.\S+/.test(value) ? null : 'Email is invalid') : 'Email is required';
      case 'phoneNumber':
        return value.trim() ? (/^\d{10}$/.test(value.replace(/\D/g, '')) ? null : 'Phone number is invalid (should be 10 digits)') : 'Phone number is required';
      case 'reason':
        return value.trim() ? null : 'Reason for joining is required';
      case 'recommendingDevotee':
        return value.trim() ? null : 'Recommending devotee is required';
      default:
        return null;
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFieldErrors(prev => ({ ...prev, [name]: error }));
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) errors[key] = error;
    });
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!guidelinesRead) {
      setError('Please read and confirm the Bhakti Sastri Guidelines before submitting.');
      return;
    }
    if (!validateForm()) {
      setError('Please correct the errors in the form.');
      return;
    }
    try {
        const isRegistered = await checkIfEmailRegistered(formData.email);
      if (isRegistered) {
        setError('This email has already been registered.');
        return;
      }

      await addRegistrationToFirestore(formData);
      setSuccess(true);
      setError(null);
      setModalOpen(true);
      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        initiatedName: '',
        email: '',
        phoneNumber: '',
        reason: '',
        recommendingDevotee: '',
      });
      setGuidelinesRead(false);
      setFieldErrors({});
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSuccess(false);
  };

  if (currentUser) {
    return (
      <Container>
        <Header as='h1' textAlign='center' style={{ marginTop: '2em' }}>
          Welcome, {currentUser.displayName || currentUser.email}!
        </Header>       
      </Container>
    );
  }

  return (
    <Container>
      <Header as='h1' textAlign='center' style={{ marginTop: '2em' }}>
        Bhakti-Śāstrī Course Registration - 2024
      </Header>     
      
      <Grid columns={2} stackable>
        <Grid.Column>
          <Segment>
            <Header as='h3'>Course Guidelines</Header>
            <Label color='green' ribbon size='large' style={{ marginBottom: '1em', fontSize: '1.2em' }}>
              Course Fee: $250
            </Label>
            <p style={{ 
              color: '#2C662D', 
              fontStyle: 'italic', 
              marginTop: '-0.5em', 
              marginBottom: '1em',
              fontSize: '1em'
            }}>
              * Payment is not required for registration
            </p>
            <Message
              info
              size='large'
              style={{
                marginTop: '1em',
                marginBottom: '1em',
                fontSize: '1.1em',
                backgroundColor: '#e8f4fd',
                boxShadow: '0 0 0 1px #a9d5de inset, 0 0 0 0 transparent'
              }}
            >              
              <p style={{ color: '#276f86' }}><strong>Note:</strong> Payment is not required at the time of registration. You will be contacted with payment instructions after your registration is reviewed.</p>
            </Message>
            <p>
              Before proceeding with your submission, please carefully review the Bhakti Sastri Guidelines. 
              These guidelines contain important information about the course structure, expectations, and requirements.
            </p>
            <Button
              as='a'
              href="https://firebasestorage.googleapis.com/v0/b/bvadallas-47ef7.appspot.com/o/Bhakti%20Sastri%20Guidelines.pdf?alt=media&token=e46fe06c-f944-4286-8473-59a8566d670f"
              target="_blank"
              rel="noopener noreferrer"
              color='blue'
              icon
              labelPosition='left'
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              <Icon name='file pdf outline' />
              Download Bhakti Sastri Guidelines
            </Button>            
            <Message
              info
              style={{
                marginTop: '1em',
                marginBottom: '1em',
                fontSize: '1.1em',
                backgroundColor: '#e8f4fd',
                boxShadow: '0 0 0 1px #a9d5de inset, 0 0 0 0 transparent'
              }}
            >
              <Form.Field>
                <Checkbox 
                  label={
                    <label style={{ color: '#276f86' }}>
                      I have read, understood, and agree to follow the Bhakti Sastri Guidelines.
                    </label>
                  }
                  checked={guidelinesRead}
                  onChange={(e, data) => setGuidelinesRead(data.checked)}
                />
              </Form.Field>
            </Message>
          </Segment>
        </Grid.Column>
        
        <Grid.Column>
          <Segment>
            <Header as='h3'>Registration Form</Header>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label='First Name'
                name='firstName'
                value={formData.firstName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={fieldErrors.firstName}
                required
              />
              <Form.Input
                fluid
                label='Last Name'
                name='lastName'
                value={formData.lastName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={fieldErrors.lastName}
                required
              />
              <Form.Input
                fluid
                label='Initiated Name'
                name='initiatedName'
                value={formData.initiatedName}
                onChange={handleInputChange}
              />
              <Form.Input
                fluid
                label='Email'
                name='email'
                type='email'
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={fieldErrors.email}
                required
              />
              <Form.Input
                fluid
                label='Phone Number'
                name='phoneNumber'
                type='tel'
                value={formData.phoneNumber}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={fieldErrors.phoneNumber}
                required
              />
              <Form.TextArea
                label='Reason for Joining the Course'
                name='reason'
                value={formData.reason}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={fieldErrors.reason}
                required
              />
              <Form.Input
                fluid
                label='Recommending Devotee'
                name='recommendingDevotee'
                value={formData.recommendingDevotee}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={fieldErrors.recommendingDevotee}
                required
              />
              <Button type='submit' primary disabled={!guidelinesRead}>
                Register
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
      
      {error && <Message negative>{error}</Message>}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        size="small"
      >
        <Header icon="check circle" content="Registration Successful" />
        <Modal.Content>
          <p>Your registration for the Bhakti-Śāstrī Course has been successfully submitted. We will contact you via Email with next steps and more information. </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleCloseModal}>
            <Icon name="checkmark" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}