import { FETCH_COURSE, FETCH_COURSES } from "./courseConstants";

export function listenToCourses(courses){
    return {
        type: FETCH_COURSES,
        payload: courses
    }
}

export function listenToCourse(course){
    return {
        type: FETCH_COURSE,
        payload: course
    }
}