/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Label,
  Segment,
  SegmentGroup,
  Select,
} from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import TextArea from "../../app/common/form/TextArea";
import * as Yup from "yup";
import {
  addToSubmittedAssignment,
  listenToAssignmentFromFireStore,
} from "../../app/firestore/firestoreService";
import { useHistory } from "react-router-dom";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { useDispatch, useSelector } from "react-redux";
import { listenToAssignment } from "./assignmentActions";
import { useState } from "react";
import { useEffect } from "react";
import {
  FetchMarkingKeys,
  FetchSubmittedAssignment,
  updateSubmittedAssignment,
} from "../../app/api/apiservice";

export default function GradeAssignment({ match }) {
  const dispatch = useDispatch();
  const { studentId, courseId, assignmentId } = match.params;
  const history = useHistory();
  //const assignment = useSelector((state) => state.assignment.assignment);
  // const submittedAssignment = assignment?.submittedAssignments?.find(
  //   (assignment) => assignment?.userId === studentId
  // );

  const [fetchedAssignment, setFetchedAssignment] = useState({});

  const [mKeys, setMKeys] = useState([]);
  const [mKeysFromApi, setMKeysFromApi] = useState({});

  // useFirestoreDoc({
  //   query: () =>
  //     listenToAssignmentFromFireStore(
  //       match.params.courseId,
  //       match.params.assignmentId
  //     ),
  //   data: (assignment) => dispatch(listenToAssignment(assignment)),
  //   deps: [dispatch],
  // });

  useEffect(() => {
    const fetchData = async () => {
      const markingKeysResult = await FetchMarkingKeys();
      if (markingKeysResult !== null) {
        setMKeysFromApi(markingKeysResult.data.data);
      }

      const result = await FetchSubmittedAssignment(
        courseId,
        assignmentId,
        studentId
      );

      if (
        result.data.data.fileUrl === null ||
        result.data.data.fileUrl === undefined
      ) {
        console.log("Error");
      } else {
        setFetchedAssignment(result.data.data);
      }
    };

    fetchData().catch(console.error);
  }, []);

  console.log(mKeysFromApi);
  //let submittedAssignment = fetchedAssignment;

  function handleMk(e, data) {
    setMKeys(mKeysFromApi?.[data.value]);
  }

  function testFunction(values) {
    const mk1 = document.getElementById(1).value;
    const mk2 = document.getElementById(2).value;
    const mk3 = document.getElementById(3).value;
    const mk4 = document.getElementById(4).value;

    values.mk1 = mk1;
    values.mk2 = mk2;
    values.mk3 = mk3;
    values.mk4 = mk4;

    return values;
  }

  let markingKeys = [];
  
  if (assignmentId == 27) 
    {
      markingKeys = [
        { key: "mk15", text: "Marking Key 1", value: "mk15" },
        { key: "mk16", text: "Marking Key 2", value: "mk16" },
        { key: "mk17", text: "Marking Key 3", value: "mk17" },
      ]
    }
    else if (assignmentId == 28)
    {
      markingKeys = [
        { key: "mk18", text: "Marking Key 1", value: "mk18" },
        { key: "mk19", text: "Marking Key 2", value: "mk19" },
        { key: "mk20", text: "Marking Key 3", value: "mk20" },
      ]
    }


  const initialValues =
    fetchedAssignment?.mk1Num !== null ||
    fetchedAssignment?.mk1Num !== undefined
      ? {
          comments: fetchedAssignment?.comments,
          mk1: mKeys[0],
          mk2: mKeys[1],
          mk3: mKeys[2],
          mk4: mKeys[3],
          mk1Num: fetchedAssignment?.mk1Num,
          mk2Num: fetchedAssignment?.mk2Num,
          mk3Num: fetchedAssignment?.mk3Num,
          mk4Num: fetchedAssignment?.mk4Num,
        }
      : {
          comments: "",
          mk1: "",
          mk2: "",
          mk3: "",
          mk4: "",
          mk1Num: 0,
          mk2Num: 0,
          mk3Num: 0,
          mk4Num: 0,
        };

  return (
    <SegmentGroup>
      <Segment>
        <Header content="Grade Assignment" />
        <a
          href={fetchedAssignment?.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <Button color="teal" content="Download Submitted Assignment" />
        </a>
      </Segment>
      <br />
      <div>
        <Label size="large" color="teal" content="Awarded Grade: " />{" "}
        <Label size="large" color="green" content={fetchedAssignment?.grade} />
      </div>
      <br />
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            mk1: fetchedAssignment?.mk1,
            mk1Num: fetchedAssignment?.mk1Num,
            mk2: fetchedAssignment?.mk2,
            mk2Num: fetchedAssignment?.mk2Num,
            mk3: fetchedAssignment?.mk3,
            mk3Num: fetchedAssignment?.mk3Num,
            mk4: fetchedAssignment?.mk4,
            mk4Num: fetchedAssignment?.mk4Num,
            comments: fetchedAssignment?.comments,
          }}
          validationSchema={Yup.object({
            comments: Yup.string().required(),
            mk1Num: Yup.number().positive().required().max(5),
            mk2Num: Yup.number().positive().required().max(5),
            mk3Num: Yup.number().positive().required().max(5),
            mk4Num: Yup.number().positive().required().max(5),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              values = testFunction(values);
              console.log(values);
              const totalGrade =
                values.mk1Num + values.mk2Num + values.mk3Num + values.mk4Num;
              const response = await updateSubmittedAssignment(
                courseId,
                assignmentId,
                values,
                totalGrade,
                studentId
              );
              if (response.data.data === false)
                setErrors({ err: "Error Submitting. Please contact tech" });
              else {
                setSubmitting(false);
                history.push(
                  `/submittedAssignments/${courseId}/${assignmentId}`
                );
              }
            } catch (error) {
              setErrors({ err: error.message });
            }
          }}
        >
          {({ isSubmitting, submitForm, isValid, errors }) => (
            <Form className="ui form">
              <TextArea
                name="comments"
                placeholder="Enter Comments Here"
                rows={10}
              />
              <Select
                name="mk"
                placeholder="Select Marking Key"
                options={markingKeys}
                onChange={handleMk}
              />
              <br />
              {mKeys.length > 0 && (
                <Grid>
                  <Grid.Column width={14}>
                    <TextInput name="mk1" id={1} value={mKeys[0]} />
                    <TextInput name="mk2" id={2} value={mKeys[1]} />
                    <TextInput name="mk3" id={3} value={mKeys[2]} />
                    <TextInput name="mk4" id={4} value={mKeys[3]} />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <TextInput name="mk1Num" type="number" />
                    <TextInput name="mk2Num" type="number" />
                    <TextInput name="mk3Num" type="number" />
                    <TextInput name="mk4Num" type="number" />
                  </Grid.Column>
                </Grid>
              )}

              {errors.err && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.err}
                />
              )}
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                type="submit"
                fluidsize="large"
                color="teal"
                content="Submit"
                onClick={submitForm}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </SegmentGroup>
  );
}
