import { FETCH_ASSIGNMENT, FETCH_ASSIGNMENTS, SET_QUESTION } from "./assignmentConstants";

export function listenToAssignments(assignments){
    return {
        type: FETCH_ASSIGNMENTS,
        payload: assignments
    }
}

export function listenToAssignment(assignment){
    return{
        type: FETCH_ASSIGNMENT,
        payload: assignment
    }
}

export function setQuestion(question){
    return {
        type: SET_QUESTION,
        payload: question
    }
}