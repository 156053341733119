import React, { useState } from "react";
import {
    Grid,
    Header,
    Segment,
    Item,
    Icon,
    Button,
    Label,
    GridColumn,
    Menu,
    Container,
    Dropdown,
    Card,
    Form,
    Table,
    Tab,
    Input,
    TextArea
  } from "semantic-ui-react";
  import { fetchServiceLog, postServiceLog } from "../../app/api/apiservice";
import { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { toast } from "react-toastify";

export default function ServiceLog(){
    const { currentUser } = useSelector((state) => state.auth);
    const [serviceLog, setServiceLog] = useState([]);
    const [formValues, setFormValues] = useState({});

    const history = useHistory();

    useEffect(() => {
        
        const fetchServiceLogFn = async () => {
          const result = await fetchServiceLog(currentUser?.uid, '79e65383-9673-40eb-91bf-65cce64a21d7');
    
          if (result == null) {
            console.log("Error Fetching Log");
          } else {
            let fetchedLog = result.data.data;
            let newArray = fetchedLog.map((obj) => {
              return {
                key: obj.id,
                description: obj.description,
                dateFrom: new Date(Date.parse(obj.dateFrom)).toLocaleDateString(),
                dateTo: new Date(Date.parse(obj.dateTo)).toLocaleDateString(),
                hours: obj.hours,
                supervisor: obj.supervisor
              };
            });
            
            setServiceLog((previousArray) => [...previousArray, ...newArray]);
          }
        };
    
        fetchServiceLogFn().catch(console.error);
      }, []);

      const onDateChange = async (e, data) => {
        setFormValues({ ...formValues, [data.name]: data.value });
      };

      const onInputChange = async (e, data) => {
        setFormValues({ ...formValues, [data.name]: data.value });
      };

      const onTextAreaChange = async (e, data) => {
        setFormValues({ ...formValues, [data.name]: data.value });
      };

      const handleFormSubmit = async () => {
        formValues.userId = currentUser?.uid
        formValues.moduleId = '79e65383-9673-40eb-91bf-65cce64a21d7'
        const response =  await postServiceLog(formValues)
        if(response?.data.data === null ||response?.data.data === undefined ){
            toast.error("Error Posting ServiceLog")
        }
        else{
            toast.success("Service Log posted successfully")
            history.push("/servicelog")
            let fetchedLog = response.data.data;
            let newArray = fetchedLog.map((obj) => {
              return {
                key: obj.id,
                description: obj.description,
                dateFrom: new Date(Date.parse(obj.dateFrom)).toLocaleDateString(),
                dateTo: new Date(Date.parse(obj.dateTo)).toLocaleDateString(),
                hours: obj.hours,
                supervisor: obj.supervisor
              };
            });
           
            setServiceLog(newArray);
            window.location.reload();
        }
      }

      return (
        <>
          {currentUser?.role === "student" && (
            <Card fluid>
              <Card.Content>
                <Form unstackable>                 
                  <Form.Group>
                    <SemanticDatepicker
                    required
                      name="dateFrom"
                      label="Select from date"
                      onChange={onDateChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <SemanticDatepicker
                    required
                      name="dateTo"
                      label="Select to date"
                      onChange={onDateChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Input name="supervisor" placeholder="Enter Supervisor Name" onChange={onInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <Input name="hours" placeholder="Enter Total Hours for the Week" type="number" onChange={onInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <TextArea name="description" placeholder="Enter Service Description" onChange={onTextAreaChange}/>
                  </Form.Group>
                  {/* <Form.Group>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Present</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {moduleStudents?.map((moduleStudent) => {
                          return (
                            <Table.Row key={moduleStudent.key}>
                              <Table.Cell>{moduleStudent.text}</Table.Cell>
                              <Table.Cell>
                                <Form.Checkbox
                                    name={moduleStudent.key}
                                    onChange={(e, data) => handleCheckboxChange(e,data)}
                                />                            
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Form.Group> */}
                  <br />
                  <Header as='h5'>There is no edit functionality at the moment. Please verify details before submission.</Header>
                  <Form.Group>
                    <Button primary type='submit' onClick = {handleFormSubmit}>Submit</Button>
                  </Form.Group>
                </Form>
              </Card.Content><br /><br />
              <Header content="Service Logs" />
              <Card.Content>
              <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Id</Table.HeaderCell>
                          <Table.HeaderCell>Date From</Table.HeaderCell>
                          <Table.HeaderCell>Date To</Table.HeaderCell>
                          <Table.HeaderCell>Supervisor</Table.HeaderCell>
                          <Table.HeaderCell>Hours</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {serviceLog?.map((log) => {
                          return (
                            <Table.Row key={log.key}>
                                <Table.Cell>{log.key}</Table.Cell>
                                <Table.Cell>{log.dateFrom}</Table.Cell>
                                <Table.Cell>{log.dateTo}</Table.Cell>
                                <Table.Cell>{log.supervisor}</Table.Cell>
                                <Table.Cell>{log.hours}</Table.Cell>
                                <Table.Cell>{log.description}</Table.Cell>                             
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
              </Card.Content>
            </Card>
          )}
        </>
      );
} 