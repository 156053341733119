import { Form, Formik } from "formik";
import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import TextInput from "../../app/common/form/TextInput";
import { Button, Label } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { registerInFirebase} from "../../app/firestore/firebaseService";
import SelectInput from "../../app/common/form/SelectInput";
import { register, roleData } from "../../app/api/roleOptions";

export default function RegisterForm() {
  const dispatch = useDispatch();
  return (
    <ModalWrapper size='mini' header='Register User'>
      <Formik
        initialValues={{ displayName: "", email: "", password: "" }}
        validationSchema={Yup.object({
          displayName: Yup.string().required(),
          email: Yup.string().required().email(),
          password: Yup.string().required(),
          role: Yup.string().required()
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await registerInFirebase(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
              setErrors({auth: error.message})
            setSubmitting(false);
            
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className='ui form'>
            <TextInput name='displayName' placeholder='Enter Display Name' />
            <TextInput name='email' placeholder='Enter Email' />
            <TextInput
              name='password'
              placeholder='Enter Password'
              type='password'
            />
            <SelectInput name='role' placeholder='Seelect User Role' options={roleData} />
            {/* {values.role === 'student' && (
                <SelectInput name='course' placeholder='Register For Ishopanishad' options={register} />
            )} */}
            {errors.auth && <Label basic color='red' style={{marginBottom:10}} content={errors.auth} />}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluidsize='large'
              color='teal'
              content='Register'
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
