import React from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import {Segment } from "semantic-ui-react";

export default function CreateAssignment() {
    const {currentUser} = useSelector(state => state.auth)
  function onDrop(acceptedFiles) {
    const renamedFiles = acceptedFiles.map(file => new File([file], `${currentUser.uid}`, {type:file.type}))
    handleFile(renamedFiles[0])
  }

  function handleFile(acceptedFile){
    console.log(currentUser);
    console.log(acceptedFile);  
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Segment>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Click to select file</p>
        )}
      </div>
    </Segment>
  );
}
