import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import TextInput from "../../app/common/form/TextInput";
import { delay } from "../../app/common/util/util";
import { updateUserPassword } from "../../app/firestore/firebaseService";

export default function AccountPage() {
    const history = useHistory();
  return (
    <Segment>
      <Header dividing size='large' content='Account' />
      <div>
        <Header
          color='teal'
          sub
          content='Change Password:'
          style={{ marginBottom: 20 }}
        />
        <Formik
          validationSchema={Yup.object({
            newPassword1: Yup.string().required("Password is required"),
            newPassword2: Yup.string().oneOf(
              [Yup.ref("newPassword1"), null],
              "Passwords do not match"
            ),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await updateUserPassword(values);
              toast("Password Changed");
              delay(2000);
              history.push('/events');
            } catch (error) {
              setErrors({ auth: error.message });
            } finally {
              setSubmitting(false);
            }
          }}
          initialValues={{ newPassword1: "", newPassword2: "" }}
        >
          {({ errors, isSubmitting, isValid, dirty }) => (
            <Form className='ui form'>
              <TextInput
                name='newPassword1'
                type='password'
                placeholder='New Password'
              />
              <TextInput
                name='newPassword2'
                type='password'
                placeholder='Confirm Password'
              />
              {errors.auth && (
                <Label
                  basic
                  color='red'
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <Button
                style={{display:'block'}}
                type='submit'
                disabled={!isValid || isSubmitting || !dirty}
                loading={isSubmitting}
                size='large'
                positive
                content='Update Password'
              />
            </Form>
          )}
        </Formik>
      </div>
    </Segment>
  );
}
