/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Label,
  Segment,
  SegmentGroup,
  Select,
} from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import TextArea from "../../app/common/form/TextArea";
import * as Yup from "yup";
import {
  updateSubmittedAssignment,
  listenToAssignmentFromFireStore,
} from "../../app/firestore/firestoreService";
import { useHistory } from "react-router-dom";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { useDispatch, useSelector } from "react-redux";
import { listenToAssignment } from "./assignmentActions";
import { useState } from "react";

export default function ReviewAssignment({ match }) {
  const dispatch = useDispatch();
  const { studentId, courseId, assignmentId } = match.params;
  const history = useHistory();
  const assignment = useSelector((state) => state.assignment.assignment);
  const submittedAssignment = assignment?.submittedAssignments?.find(
    (assignment) => assignment?.userId === studentId
  );
  const { currentUser } = useSelector((state) => state.auth);

  const [mKeys, setMKeys] = useState([]);
  const [clicked, setClicked] = useState(false);

  useFirestoreDoc({
    query: () =>
      listenToAssignmentFromFireStore(
        courseId,
        assignmentId
      ),
    data: (assignment) => dispatch(listenToAssignment(assignment)),
    deps: [dispatch],
  });

  function handleMk(e, data) {
    setMKeys(assignment?.[data.value]);
    setClicked(true);
  }

  function assignValues(values) {
    const mk1 = document.getElementById(1).value;
    const mk2 = document.getElementById(2).value;
    const mk3 = document.getElementById(3).value;
    const mk4 = document.getElementById(4).value;

    values.mk1 = mk1;
    values.mk2 = mk2;
    values.mk3 = mk3;
    values.mk4 = mk4;

    return values;
  }

  const markingKeys = [
    { key: "mk1", text: "Marking Key 1", value: "mk1" },
    { key: "mk2", text: "Marking Key 2", value: "mk2" },
  ];

  var reviewer = assignment?.reviewers?.filter(
    (reviewer) => reviewer.reviewerId === currentUser?.uid
  );

  var allotted = reviewer?.length>0 ? reviewer[0].allottedStudents?.find(id => id === studentId) : null;

  const initialValues =
    submittedAssignment !== undefined
      ? {
          comments: submittedAssignment?.comments,
          mk1: submittedAssignment?.markingKey?.mk1?.key,
          mk2: submittedAssignment?.markingKey?.mk2?.key,
          mk3: submittedAssignment?.markingKey?.mk3?.key,
          mk4: submittedAssignment?.markingKey?.mk4?.key,
          mk1Num: submittedAssignment?.markingKey?.mk1?.score,
          mk2Num: submittedAssignment?.markingKey?.mk2?.score,
          mk3Num: submittedAssignment?.markingKey?.mk3?.score,
          mk4Num: submittedAssignment?.markingKey?.mk4?.score,
        }
      : {
          comments: "",
          mk1: "",
          mk2: "",
          mk3: "",
          mk4: "",
          mk1Num: 0,
          mk2Num: 0,
          mk3Num: 0,
          mk4Num: 0,
        };

  return (
    <SegmentGroup>
      <Segment>
        <Header content='Grade Assignment' />
        <a
          href={submittedAssignment?.submittedFile}
          target='_blank'
          rel='noopener noreferrer'
          download
        >
          <Button color='teal' content='Download Submitted Assignment' />
        </a>
      </Segment><br/>
      <div>
        <Label size='large' color='teal' content='Awarded Grade: ' />{" "}
        <Label
          size='large'
          color='green'
          content={submittedAssignment?.grade}
        />
      </div><br/>
      <Segment>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            comments: Yup.string().required(),
            mk1Num: Yup.number().positive().required().max(5),
            mk2Num: Yup.number().positive().required().max(5),
            mk3Num: Yup.number().positive().required().max(5),
            mk4Num: Yup.number().positive().required().max(5),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              values = assignValues(values);
              const totalGrade =
                values.mk1Num + values.mk2Num + values.mk3Num + values.mk4Num;
              await updateSubmittedAssignment(
                courseId,
                assignmentId,
                values,
                totalGrade,
                studentId
              );
              setSubmitting(false);
              history.push(`/submittedAssignments/${courseId}/${assignmentId}`);
            } catch (error) {
              setErrors({ err: error.message });
            }
          }}
        >
          {({ isSubmitting, submitForm, isValid, errors }) => (
            <Form className='ui form'>
              <TextArea
                name='comments'
                placeholder='Enter Comments Here'
                rows={10}
              />
              <Select
                name='mk'
                placeholder='Select Marking Key'
                options={markingKeys}
                onChange={handleMk}
              />
              <br />
              
                <Grid>
                  <Grid.Column width={14}>
                    <TextInput name='mk1' id={1} value={clicked ? mKeys[0] : submittedAssignment?.markingKey?.mk1?.key} />
                    <TextInput name='mk2' id={2} value={clicked ? mKeys[1] : submittedAssignment?.markingKey?.mk2?.key} />
                    <TextInput name='mk3' id={3} value={clicked ? mKeys[2] : submittedAssignment?.markingKey?.mk3?.key} />
                    <TextInput name='mk4' id={4} value={clicked ? mKeys[3] : submittedAssignment?.markingKey?.mk4?.key} />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <TextInput name='mk1Num' type='number' />
                    <TextInput name='mk2Num' type='number' />
                    <TextInput name='mk3Num' type='number' />
                    <TextInput name='mk4Num' type='number' />
                  </Grid.Column>
                </Grid>
              

              {errors.err && (
                <Label
                  basic
                  color='red'
                  style={{ marginBottom: 10 }}
                  content={errors.err}
                />
              )}
              <br />
              <Button
                loading={isSubmitting}
                disabled={!isValid || isSubmitting || allotted === undefined}
                type='submit'
                fluidsize='large'
                color='teal'
                content='Review'
                onClick={submitForm}
              /> <Label pointing='left'>Please click review even if no changes were made</Label>
            </Form>
          )}
        </Formik>
      </Segment>
    </SegmentGroup>
  );
}
