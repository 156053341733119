import React from "react";
//import { useSelector } from "react-redux";
//import { Header, Segment } from "semantic-ui-react";
import AssignmentListItem from "./AssignmentListItem";

export default function AssignmentList({ assignments,  courseId}) {
  //const { currentUser } = useSelector((state) => state.auth);
  console.log("ASsignments", assignments)

  return (
    <>
      {assignments.map((assignment) => {
        return <AssignmentListItem courseId={courseId} assignment={assignment} key={assignment.id} />
      })}
    </>
  );
}

// const isUserRegisteredForCourse = course.users.find(
//     (user) => user === currentUser?.uid
//   );
//   if (isUserRegisteredForCourse) {
//     return <CourseListItem course={course} key={course.id} />;
//   } else {
//     return (
//       <Segment placeholder>
//         <Header
//           textAlign='center'
//           content='You are not registered for any courses. Please Contact Iskcon Dallas to know more about Bhakti Shashtri Courses'
//         />
//       </Segment>
//     );
//   }
