import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { listenToUsers } from '../../app/common/users/userActions';
import { listenToUsersFromFireStore } from '../../app/firestore/firestoreService';
import useFirestoreCollection from '../../app/hooks/useFireStoreCollection';

export default function GradesDashboard(){
    const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const {currentUser} = useSelector(state => state.auth)

  console.log(currentUser);

  useFirestoreCollection({
    query: () => listenToUsersFromFireStore(),
    data: users =>  dispatch(listenToUsers(users)),
    deps: [dispatch]
  })

  let students = users?.filter(user => user.role === 'student' && !user.email.includes('test') )
  console.log(students);



  return(
      <>
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Header 1</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        </Table>
      </>
  )
}